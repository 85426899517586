<template>
   <div class="share-item">
      <div class="share__img">
         <img :src="`/img/${iconName}`" alt="" />
      </div>
      <p class="share__text">
         {{ name }}
      </p>
   </div>
</template>

<script>
export default {
   props: {
      iconName: {
         required: true,
         type: String,
      },

      name: {
         required: true,
         type: String,
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.share-item
   border-radius: 50px
   padding: 0.5rem 0.8rem
   background-color: #f5f5f5
   font-size: 0.8rem
   display: flex
   & *
      cursor: pointer

.share__img
   margin-right: 0.5rem
   height: 15px
   max-width: 15px
   width: 100%

   & img
      height: 100%
      width: 100%
</style>